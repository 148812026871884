import Vue from 'vue';
import VueGtag from 'vue-gtag';
import App from './App.vue';
import router from './router';
import siteAPIPlugin from './siteAPI';

const SocialSharing = require('vue-social-sharing');


Vue.config.productionTip = false;
Vue.use(siteAPIPlugin);

Vue.use(VueGtag, {
  config: { id: 'UA-113974301-1' },
}, router);

Vue.use(SocialSharing);

new Vue({
  router,
  render: h => h(App),
}).$mount('#app');
