<template>
  <router-link
    :to="`/search/${encodeURIComponent(word)}`"
    class="reference-link"
  >{{word}}</router-link>
</template>

<script>
export default {
  name: 'Reference',
  props: {
    word: {
      type: String,
      required: true,
    },
  },
};
</script>
