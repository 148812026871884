<template>
  <div class="share" v-if="definition">
    <transition name="share">
      <div v-if="active === false">
        <ul>
          <li @click="active = true"><img src="../assets/donut.svg"><span>Share this</span></li>
        </ul>
      </div>
    </transition>
    <transition name="share">
      <div v-if="active === true">
        <ul>
          <li>
            <social-sharing
              :title="'Definition of the word ' + definition.word"
              :description="cleanDefinition"
              :url="pageUrl"
              inline-template
            >
              <network network="facebook">
                <img src="../assets/facebook.svg"><span>Facebook</span>
              </network>
            </social-sharing>
          </li>
          <li>
            <social-sharing
              :title="`Definition of the word '${definition.word}, from the site Stink and Pink.`"
              :description="cleanDefinition"
              :quote="cleanDefinition"
              :url="pageUrl"
              twitter-user="StinkPink1"
              inline-template
            >
              <network network="twitter">
                <img src="../assets/twitter.svg"><span>Twitter</span>
              </network>
            </social-sharing>
          </li>
          <li @click="active = false">
            <img src="../assets/close.svg"><span>Close</span>
          </li>
        </ul>
      </div>
    </transition>
  </div>
</template>

<script>
const Filter = require('bad-words');

export default {
  name: 'Share',
  props: {
    definition: {
      type: Object,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      active: null,
      filter: new Filter(),
    };
  },
  computed: {
    cleanDefinition() {
      return this.filter.clean(this.definition.definition);
    },
    pageUrl() {
      const { type, defid, index } = this.$route.params;
      return `https://stinkand.pink/${type}/${defid}${index ? `/${index}` : ''}`;
    },
  },
  mounted() {
    this.active = false;
  },
  methods: {
  },
};
</script>

<style lang="scss">
  @import '../assets/scss/variables';
  .share > div {
    background: $black;
    bottom: 0;
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
    left: 50%;
    position: absolute;
    transform: translate(-50%, 0%);
    ul {
      list-style: none;
      padding: 0;
      margin: 9px 0 5px;
      li {
        padding: 0 35px;
        img {
          width: 32px
        }
        span {
          color: $pink;
          display: inline-block;
          font-size: 33px;
          line-height: 33px;
          margin-left: 7px;
          min-width: 140px;
          outline: none;
          padding-bottom: 5px;
          text-align: center;
          @include for-phone-only {
            font-size: 29px;
          }
        }
        display: flex;
        justify-content: center;
        cursor: pointer;
        width: 100%;
      }
    }
  }
</style>
