 <template>
  <div>
    <div :class="['content', 'content-loading']" id="result" v-if="showAnimation">
      <Loading :loading="loadingDefinition" @finished-animation="setLoaded(false)"/>
    </div>
    <transition name="slide-left">
      <div :class="[{'under-size': underSize}, 'content']" id="result" v-if="loaded" ref="content">
        <div
          v-if="definition"
          :class="[{'bounceIn': !scaleDown}, {'hinge': scaleDown}]"
          ref="animationContent"
        >
          <h1 class="word">{{ definition.word }}</h1>
          <div class="definition" v-html="parsedDefinition" />
          <div>
            <SearchButton />
          </div>
          <div>
            <DifferentButton
              :to="nextDefinition"
              v-if="sharedState.results.length >= 2"
            />
            <a href="#" ref="different" class="hidden">blah</a>
          </div>
        </div>
      </div>
    </transition>
    <Share v-if="showShare" :definition="definition" />
  </div>
</template>

<script>
import DifferentButton from '../components/DifferentButton.vue';
import SiteStore from '../SiteStore';
import Loading from '../components/Loading.vue';
import SearchButton from '../components/SearchButton.vue';
import Share from '../components/Share.vue';

export default {
  name: 'Result',
  components: {
    DifferentButton,
    Loading,
    SearchButton,
    Share,
  },
  data() {
    return {
      definition: null,
      loaded: false,
      loadingDefinition: true,
      scaleDown: false,
      sharedState: SiteStore.state,
      showAnimation: false,
      showShare: false,
      underSize: false,
    };
  },
  computed: {
    parsedDefinition() {
      return this.definition.definition.replace(/([[])(.+?)([\]])/g, (match, p1, p2, p3) => `${p1.substring(0, p1.length - 1)}<a href="/search/${encodeURI(p2)}">${p2}</a>${p3.substring(1)}`);
    },
    nextDefinition() {
      // There will only ever be one M definition so can assume that we can link to /u/
      if (SiteStore.state.results.length >= 2) {
        // eslint-disable-next-line max-len
        const currentIndex = SiteStore.state.results.findIndex(d => d.defid === this.definition.defid);
        const nextIndex = (currentIndex + 1) % SiteStore.state.results.length;
        const item = SiteStore.state.results[nextIndex];
        return item.miriam ? `/m/${item.word}/${nextIndex}` : `/u/${item.defid}`;
      }
      return '';
    },
  },
  watch: {
    // eslint-disable-next-line object-shorthand
    '$route.params.defid'() {
      this.swapDefinitions();
    },
    // eslint-disable-next-line object-shorthand
    '$route.params.index'() {
      this.swapDefinitions();
    },
  },
  mounted() {
    this.setDefinition();
  },
  methods: {
    setUnderSize() {
      this.underSize = this.$refs.animationContent.clientHeight < this.$refs.content.clientHeight;
    },
    setDefinition() {
      const { defid } = this.$route.params;
      if (this.$route.params.type === 'u') {
        this.definition = SiteStore.getDefinition(parseInt(defid, 10));
        if (!this.definition) {
          this.showAnimation = true;
          SiteStore.clearResults();
          // PLAY LOADING
          this.$siteAPI.defineUrbanDefid(defid).then((result) => {
            const [definition] = result.data.list;
            if (!definition) {
              // No Result - might have been deleted
              this.$router.push(`/no-definition/${definition.word}`);
            }
            SiteStore.setResults([definition], definition.word);
            this.definition = definition;

            // Now get the other results incase they want to try another definition
            this.$siteAPI.defineUrban(definition.word).then((results) => {
              this.loadingDefinition = false;
              SiteStore.setResults(results.data.list, definition.word);
              // could set a variable to say loading has finished
            });
          });
        } else {
          // this is stupid
          this.setLoaded();
        }
      } else {
        // Miriam definition
        const { index } = this.$route.params;
        this.definition = SiteStore.getDefinitionByIndex(index);
        if (!this.definition) {
          this.showAnimation = true;
          SiteStore.clearResults();
          this.$siteAPI.defineMiriam(defid).then((miriamResults) => {
            SiteStore.clearResults();
            const { definitions, word } = miriamResults.data;
            if (definitions) {
              SiteStore.setResultsMiriam(definitions, word);
              this.definition = SiteStore.getDefinitionByIndex(index);
              this.loadingDefinition = false;
            } else {
              // No Result
              this.$router.push(`/no-definition/${word}`);
            }
          }).catch(() => {
            this.$router.push(`/no-definition/${defid}`);
          });
        } else {
          this.setLoaded();
        }
      }
    },
    setLoaded(delay = true) {
      // this delay shouldn't be there, but isn't loaded before scroll for some reason
      setTimeout(() => {
        this.loaded = true;
        this.$nextTick(() => {
          this.setUnderSize();
        });
      }, (delay ? 700 : 30));
      setTimeout(() => {
        this.showShare = true;
      }, 3000);
    },
    swapDefinitions() {
      this.scaleDown = true;
      setTimeout(() => {
        this.setDefinition();
        this.$nextTick(() => {
          this.scaleDown = false;
        });
      }, 2000);
    },
  },
};
</script>
<style lang="scss">
  @import '../assets/scss/variables';
  .content#result {
    padding: 135px 4.5vw;
    &.under-size {
      overflow: visible;
      // min-height: 100vh;
      // display: flex;
      // justify-content: center;
      // align-items: center;
    }
    @include for-phone-only {
      padding: 80px 15px 135px;
    }
    @include for-desktop-up {
      padding: 135px;
    }
  }
  .word {
    font-size: 4vw;
    margin: 0;
    @include for-phone-only {
      font-size: 30px;
      margin: 0 0 10px;
    }
    @include for-desktop-up {
      font-size: 50px;
      margin: 0 0 10px;
    }
  }
  .definition {
    white-space: pre-wrap;
    font-size: 2.3vw;
    margin: 0 0 2vw;
    @include for-phone-only {
      font-size: 18px;
      margin: 0 0 20px;
    }
    @include for-desktop-up {
      font-size: 30px;
      margin: 0 0 30px;
    }
  }
</style>
