import axios from 'axios';

require('es6-promise').polyfill();

export const siteAPI = {
  handleErrors(error) {
    console.error(error);
  },
  autocomplete(term) {
    return axios.get(`https://api.urbandictionary.com/v0/autocomplete?term=${term}`).catch(this.handleErrors);
  },
  defineUrban(term) {
    return axios.get(`https://api.urbandictionary.com/v0/define?term=${term}`).catch(this.handleErrors);
  },
  defineUrbanDefid(defid) {
    return axios.get(`https://api.urbandictionary.com/v0/define?defid=${defid}`).catch(this.handleErrors);
  },
  defineMiriam(term) {
    return axios.get(`https://wordsapiv1.p.rapidapi.com/words/${term}/definitions`, {
      headers: {
        'x-rapidapi-host': 'wordsapiv1.p.rapidapi.com',
        'x-rapidapi-key': 'Xz9w0gT5JImshiP127zMOl8evNKNp1bpvJKjsnLh9WQ6a0Ha67',
      },
    });
  },
};

export default class siteAPIPlugin {
  static install(Vue) {
    // eslint-disable-next-line no-param-reassign
    Vue.prototype.$siteAPI = siteAPI;
  }
}
