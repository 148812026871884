<template>
  <div :class="['loading']">
    <img src="../assets/smell-1.svg" id="icon-1" :class="[{active: current === 'icon-1'}]"/>
    <img src="../assets/smell-2.svg" id="icon-2" :class="[{active: current === 'icon-2'}]"/>
    <img src="../assets/smell-3.svg" id="icon-3" :class="[{active: current === 'icon-3'}]"/>
    <img src="../assets/smell-4.svg" id="icon-4" :class="[{active: current === 'icon-4'}]"/>
    <img src="../assets/smell-5.svg" id="icon-5" :class="[{active: current === 'icon-5'}]"/>
    <img src="../assets/pink-1.svg" id="icon-6" :class="[{active: current === 'icon-6'}]"/>
    <img src="../assets/pink-2.svg" id="icon-7" :class="[{active: current === 'icon-7'}]"/>
    <img src="../assets/pink-3.svg" id="icon-8" :class="[{active: current === 'icon-8'}]"/>
    <img src="../assets/pink-4.svg" id="icon-9" :class="[{active: current === 'icon-9'}]"/>
    <img src="../assets/pink-5.svg" id="icon-10" :class="[{active: current === 'icon-10'}]"/>
  </div>
</template>

<script>
export default {
  name: 'Loading',
  props: {
    loading: {
      required: false,
      default: false,
    },
  },
  data() {
    return {
      count: 0,
      current: null,
      timer: null,
    };
  },
  mounted() {
    this.setIcon();
    this.count += 1;
    this.timer = setInterval(() => {
      if (this.count >= 2 && !this.loading) {
        this.current = null;
        clearInterval(this.timer);
        this.$nextTick(() => {
          this.$emit('finished-animation');
        });
      } else {
        this.setIcon();
        this.count += 1;
      }
    }, 1900);
  },
  methods: {
    setIcon() {
      this.current = `icon-${Math.floor(Math.random() * 5) + 1 + (this.count % 2 === 0 ? 0 : 5)}`;
    },
  },
};
</script>

<style lang="scss">
  @import '../assets/scss/variables';
  .loading {
    @include for-phone-only {
      max-width: 100%;
    }
    img {
      width: 28vw;
      display: none;
      margin: 0 auto;
      @include for-phone-only {
        width: 70vw;
      }
      &.active {
        display: block;
        animation-name: loading;
        animation-duration: 1.9s;
        animation-iteration-count: 1;
      }
    }
  }
</style>
