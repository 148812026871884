<template>
  <div class="home">
    <div :class="['content', 'content-loading']">
      <Loading :loading="!this.path" @finished-animation="$router.push(path);"/>
    </div>
  </div>
</template>

<script>
import SiteStore from '../SiteStore';
import Loading from '../components/Loading.vue';

export default {
  name: 'Search',
  components: {
    Loading,
  },
  data() {
    return {
      path: null,
    };
  },
  mounted() {
    this.define();
  },
  methods: {
    define(t = null) {
      SiteStore.clearResults();
      let { term } = this.$route.params;
      term = t ?? term;
      this.$siteAPI.defineUrban(term).then((results) => {
        // Filter out any results that are not the exact word
        SiteStore.setResults(results.data.list, term);
        const filteredResults = SiteStore.getResults();
        if (!filteredResults.length && term.charAt(term.length - 2) === '\'' && term.charAt(term.length - 1) === 's') {
          // try stripping 's off and trying again
          term = term.substr(0, term.length - 2);
          this.define(term);
        } else if (filteredResults.length) {
          this.path = `/u/${filteredResults[0].defid}`;
        } else {
          // There is no UB definition
          this.$siteAPI.defineMiriam(term).then((miriamResults) => {
            const { definitions } = miriamResults.data;
            if (definitions) {
              SiteStore.setResultsMiriam(definitions, term);
              const parsedResults = SiteStore.getResults();
              this.path = `/m/${parsedResults[0].word}/0`;
            } else {
              this.path = `/no-definition/${term}`;
            }
          }).catch(() => {
            this.path = `/no-definition/${term}`;
          });
        }
      });
    },
  },
};
</script>
