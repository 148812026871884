<template>
  <router-link :to="to" class="button" id="different">
    <span class="button-text">Try a different definition</span>
    <span class="button-icon"></span>
  </router-link>
</template>

<script>
export default {
  name: 'DifferentButton',
  props: {
    to: {
      type: String,
      required: true,
    },
  },
};
</script>

<style lang="scss">
  @import '../assets/scss/variables';
  .button#different {
    @include for-desktop-up {
      &:hover {
        .button-text {
          transform: translateY(-100%);
        }
        .button-icon {
          top: 0%;
        }
      }
    }
    @include for-phone-only {
      &:active {
        .button-text {
          transform: translateY(100%);
        }
        .button-icon {
          top: 0%;
        }
      }
    }
    .button-icon {
      background: $black url('../assets/flamingo.svg');
      background-repeat: no-repeat;
      background-position: center;
      background-size: 43px;
      top: 100%;
    }
  }
</style>
