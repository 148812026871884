<template>
  <div id="app" :class="{'menu-active': menuActive}">
    <div class="wrapper">
      <Menu v-on:toggle-menu="menuActive = !menuActive" :menu-active="menuActive"/>
      <div class="main">
        <transition
          name="spinning"
        >
          <img
            src="./assets/shaka.svg"
            class="menu-icon"
            v-on:click.stop="menuActive = !menuActive"
            v-if="loaded"
          >
        </transition>
        <transition
          :name="transitionName"
          duration="700"
        >
          <router-view/>
        </transition>
      </div>
    </div>
    <img src="./assets/smell-1.svg" class="hidden" />
    <img src="./assets/smell-2.svg" class="hidden" />
    <img src="./assets/smell-3.svg" class="hidden" />
    <img src="./assets/smell-4.svg" class="hidden" />
    <img src="./assets/smell-5.svg" class="hidden" />
    <img src="./assets/pink-1.svg" class="hidden" />
    <img src="./assets/pink-2.svg" class="hidden" />
    <img src="./assets/pink-3.svg" class="hidden" />
    <img src="./assets/pink-4.svg" class="hidden" />
    <img src="./assets/pink-5.svg" class="hidden" />
    <img src="./assets/twitter.svg" class="hidden" />
    <img src="./assets/facebook.svg" class="hidden" />
    <img src="./assets/close.svg" class="hidden" />
    <img src="./assets/donut.svg" class="hidden" />
  </div>
</template>

<script>
import Menu from './components/Menu.vue';

require('animate.css');

export default {
  name: 'App',
  components: {
    Menu,
  },
  data() {
    return {
      loaded: false,
      menuActive: false,
      transitionName: 'slide-left',
    };
  },
  beforeRouteUpdate(to, from, next) {
    const toDepth = to.path.split('/').length;
    const fromDepth = from.path.split('/').length;
    this.transitionName = toDepth < fromDepth ? 'slide-right' : 'slide-left';
    next();
  },
  mounted() {
    setTimeout(() => {
      this.loaded = true;
    }, 300);
  },
};
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css?family=Bree+Serif&display=swap');
@import './assets/scss/variables';
@import './assets/scss/animations';
@import './assets/scss/features';
@import './assets/scss/utilities';
  * {
    box-sizing: border-box;
  }
  html,
  body {
    margin: 0;
    background-color: $pink;
  }
  #app {
    font-family: 'Bree Serif', Georgia, 'Times New Roman', Times, serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: $black;
    height: 100vh;
    overflow: hidden;
  }
  button {
    cursor: pointer;
    font-family: 'Bree Serif', Georgia, 'Times New Roman', Times, serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  a {
    color: $black;
  }
  #app .wrapper {
    -webkit-transition: transform 300ms;
    -moz-transition: transform 300ms;
    -ms-transition: transform 300ms;
    -o-transition: transform 300ms;
    transition: transform 300ms;
    transform: translateY(-100vh);
    @include for-desktop-up {
      max-width: $desktop-breakpoint;
      margin: 0 auto;
    }
  }
  #app.menu-active .wrapper {
    transform: translateY(0vh);
  }
  .main {
    position: relative;
    height: 100vh;
    width: 100%;
  }
  .content {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    max-height: calc(100%);
    overflow-y: scroll;
    overflow-x: hidden;
    padding: 1.5vw;
    width: 100%;
    &.content-loading {
      overflow: visible;
    }
  }
</style>
