<template>
  <div class="menu">
    <img src="../assets/shaka.svg" class="menu-icon" v-on:click.stop="toggleMenu" />
    <div class="content">
      <div class="item made" :style="{fontSize:'5.5vw'}">This site was made using</div>
      <div class="item urban" :style="{fontSize:'6.7vw', textTransform:'uppercase'}">
        <a
          href="https://urbandictionary.com/"
        >Urban Dictionary</a>
      </div>
      <div
        class="item miriam"
        :style="{
          textTransform:'uppercase',
          fontSize: '11.85vw',
          textTransform: 'uppercase',
          lineHeight: '11vw'}
        "
      >
        <a href="https://wordsapi.com/">Words API</a>
      </div>
      <div
        :class="['item', 'talking', 'animated', 'delay-1s', {'tada': menuActive}]"
        :style="{fontSize:'5vw', textTransform:'uppercase'}"
      >
        <a
          href="https://talkto.stinkand.pink"
        >Try the talking version</a>
      </div>
      <div class="item credit" :style="{fontSize:'3.5vw', marginBottom: '15px'}">
        <a href="http://www.jamieconnor.com/">jamieconnor.com</a>
      </div>
      <div
        class="fb-share-button"
        data-href="https://stinkand.pink"
        data-layout="button_count"
        data-size="large"
        data-mobile-iframe="true"
      >
        <a
          target="_blank"
          href="https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fstinkand.pink%2F&amp;src=sdkpreparse"
          class="fb-xfbml-parse-ignore"
        >Share</a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Menu',
  props: {
    menuActive: {
      type: Boolean,
    },
  },
  methods: {
    toggleMenu() {
      this.$emit('toggle-menu');
    },
  },
};
</script>

<style lang="scss">
@import '../assets/scss/variables';
.menu {
  position: relative;
  height: 100vh;
  width: 100%;
  top: 0;
  left: 0;
  background-color: white;
  overflow-y: scroll;
}
@media (max-width: 480px) {
  .menu .menu-icon {
    animation-play-state: running;
  }
}
.menu .item {
  line-height: 1.3em;
}
.menu .item.talking {
  margin-top: 8px;
}
.menu .item a {
  color: #2c3e50;
  text-decoration: none;
}
.fb-share-button {
  margin-top: 30px;
}
</style>
