import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../views/Home.vue';
import NoDefinition from '../views/NoDefinition.vue';
import Result from '../views/Result.vue';
import Search from '../views/Search.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home,
  },
  {
    path: '/search/:term',
    name: 'search',
    component: Search,
  },
  {
    path: '/no-definition/:term',
    name: 'NoDefinition',
    component: NoDefinition,
  },
  {
    path: '/:type/:defid/:index?',
    name: 'result',
    component: Result,
  },
  {
    path: '*',
    name: '404',
    component: Home,
  },
];

const router = new VueRouter({
  mode: 'history',
  routes,
});

export default router;
