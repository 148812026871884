<template>
  <div
    :class="['home', {active:loaded}]"
  >
    <div class="content">
      <div
        :class="['home-header']"
      >What's a word you'd like to get a Stink or Pink definition for?</div>
      <div class="search-input-group">
        <input @keyup.enter="search" @keyup="autocomplete" v-model="term">
        <button @click="search">Search</button>
      </div>
      <div class="search-autocomplete-holder" v-show="!autocompleteWords.length">
      </div>
      <div class="search-autocomplete" v-show="autocompleteWords.length">
        <div class="search-autocomplete-header">How about one of these?</div>
        <div class="search-autocomplete-results">
          <template v-for="(word,i) of autocompleteWords">
            <div
              :class="[
                'search-autocomplete-suggestion',
                {'suggestion-loaded': !loadingAutocomplete}
              ]"
              :key="word"
            >
              <Reference :word="word"/>
              <span
                v-if="i !== autocompleteWords.length - 1"
                class="comma"
              >, </span>
            </div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Reference from '../components/Reference.vue';

export default {
  name: 'Home',
  components: {
    Reference,
  },
  data() {
    return {
      autocompleteWords: [],
      loaded: false,
      loadingAutocomplete: true,
      term: '',
    };
  },
  methods: {
    autocomplete() {
      this.loadingAutocomplete = true;
      this.$siteAPI.autocomplete(this.term).then((result) => {
        this.autocompleteWords = result.data;
        setTimeout(() => {
          this.loadingAutocomplete = false;
        }, 10);
      });
    },
    search() {
      if (this.term.length > 0) {
        this.$router.push(`/search/${encodeURI(this.term)}`);
      }
    },
  },
  mounted() {
    setTimeout(() => {
      this.loaded = true;
    }, 300);
  },
};
</script>
<style lang="scss">
  @import '../assets/scss/variables';
  .home-header {
    font-size: 6.17vw;
    transform: scale(0);
    transition: 400ms transform;
    transition-delay: 800ms;
    overflow-x: hidden;
    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    @include for-desktop-up {
      font-size: 78px;
      max-width: 1165px;
      margin: 0 auto;
    }
    .active & {
      transform: scale(1);
    }
  }
  .search-input-group {
    display: flex;
    justify-content: center;
    margin-top: 4vw;
    transition: 600ms transform;
    transition-delay: 1000ms;
    transform: translateY(50vh);
    @include for-phone-only {
      margin-top: 15px;
      margin-bottom: 15px;
    }
    @include for-desktop-up {
      margin-top: 55px;
    }
    .active & {
      transform: translateY(0);
    }
    input {
      border-top-left-radius: 16px;
      border-bottom-left-radius: 16px;
      border: 3px solid $black;
      border-right: none;
      background: transparent;
      height: 5.7vw;
      width: 44vw;
      outline: none;
      padding: 0 2.2vw;
      font-size: 3vw;
      font-family: bree serif, Helvetica, sans-serif;
      color: #2c3e50;
      @include for-phone-only {
        height: 40px;
        border: 2px solid $black;
        padding: 0 20px;
        width: 55%;
        font-size: 20px;
      }
      @include for-desktop-up {
        height: 82px;
        width: 570px;
        padding: 0 30px;
        font-size: 43px;
      }
    }
    button {
      background-color: $black;
      border-top-right-radius: 16px;
      border-bottom-right-radius: 16px;
      border: none;
      color: $pink;
      font-size: 3vw;
      height: 5.7vw;
      padding-bottom: 0.6vw;
      padding-left: 1.8vw;
      padding-right: 1.8vw;
      @include for-phone-only {
        font-size: 18px;
        height: 40px;
        padding-bottom: 3px;
        padding-left: 20px;
        padding-right: 20px;
      }
      @include for-desktop-up {
        font-size: 43px;
        height: 82px;
        padding-bottom: 9px;
        padding-left: 25px;
        padding-right: 25px;
      }
    }
  }
    .search-autocomplete-holder {
    min-height: 156px
  }
  .search-autocomplete {
    margin-top: 3vw;
    font-size: 3.37vw;
    text-align: left;
    padding: 0 4vw;
    line-height: 3.6vw;
    @include for-phone-only {
      font-size: 24px;
      line-height: 28px;
    }
    @include for-desktop-up {
      font-size: 43px;
    }
    &-header {
      margin-bottom: .8vw;
      @include for-desktop-up {
        margin-bottom: 5px;
      }
    }
    &-results {
      @include for-phone-only {
        font-size: 20px;
        line-height: 26px;
      }
      .comma {
        margin-right: 15px;
        @include for-phone-only {
          margin-right: 10px;
        }
      }
    }
    &-suggestion {
      display: inline-block;
      opacity: 0;
      transition: opacity 90ms;
      &.suggestion-loaded {
        opacity: 1;
      }
    }
  }
</style>
