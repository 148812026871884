<template>
  <div class="home">
    <div class="content">
      <div>
        <h1>
          There is no definition for the word '{{ $route.params.term }}' that is Stink or Pink.
        </h1>
        <div>
          <SearchButton />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SearchButton from '../components/SearchButton.vue';

export default {
  name: 'NoDefinition',
  components: {
    SearchButton,
  },
};
</script>
