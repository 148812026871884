export default {
  state: {
    results: [],
  },

  clearResults() {
    this.results = [];
  },

  getResults() {
    return this.state.results;
  },

  getDefinition(defid) {
    let definition = false;
    this.state.results.forEach((d) => {
      if (d.defid === defid) {
        definition = d;
      }
    });
    return definition;
  },

  getDefinitionByIndex(index) {
    return this.state.results[index];
  },

  setResults(results, term) {
    // eslint-disable-next-line max-len
    const filteredResults = results.filter(def => def.word.toLowerCase() === term.toLowerCase());
    // Sort by ratios of thumbs down to thumbs up
    filteredResults.sort((a, b) => {
      let aRatio = a.thumbs_up / (a.thumbs_up + a.thumbs_down);
      let bRatio = b.thumbs_up / (b.thumbs_up + b.thumbs_down);
      aRatio = aRatio === 1 ? 0 : aRatio;
      bRatio = bRatio === 1 ? 0 : bRatio;
      return bRatio - aRatio;
    });
    this.state.results = filteredResults;
  },

  setResultsMiriam(definitions, term) {
    const defintionsMapped = definitions.map(definition => ({
      defid: definition.definition,
      word: term,
      definition: definition.definition,
      miriam: true,
    }));
    this.state.results = defintionsMapped;
  },
};
